import React from 'react';
import PropTypes from 'prop-types'
import { Controller, Scene } from 'react-scrollmagic';
import { graphql } from "gatsby"
import Seo from '../../components/seo';
import Layout from '../../containers/layout/layout'
import Banner from '../../containers/single-project/banner'
import Heading from '../../components/shared/heading'
import Text from '../../components/shared/text'
import SectionTitle from '../../components/shared/section-title'
import Image from '../../components/image'
import Project from '../../components/project'
import {
	ProjectDetailsWrap,
	ProjectHeaderWrap,
	ProjectHeaderMeta,
	ProjectType,
	FullwidthBox,
	NextProjectWrap
} from './project-template.stc'
import {pathWithLanguage, truncateString} from "../../utils/utilFunctions";

const ProjectTemplate = ({ data, pageContext: { next, previous, lang }, ...restProps }) => {
	const { title, metaHeading, metaText, nextProjectStyle } = restProps;
	const projectData = {...data.projectsJson, ...data.projectsJson.i18n[lang]};
	const { bodyHtml, cover_image } = projectData;
	next = next ? {...next, ...next.i18n[lang]} : next;
	previous = previous ? {...previous, ...previous.i18n[lang]} : previous;

	const translations = data.translationJson.projects;
	const titles = {};
    Object.keys(translations).forEach(key => {
		titles[key] = translations[key][lang];
	});
	return (
		<Layout lang={lang}>
			<Seo
				title={projectData.title}
				description={truncateString(projectData.body.replaceAll("\n"," "), 300)}
				pathname={pathWithLanguage(lang, `/project/${projectData.jsonId}`)}
				image={data.projectsJson.page_cover_image.childImageSharp.gatsbyImageData.images.fallback.src}
				lang={lang} />
			<Banner banner_img={data.projectsJson.page_cover_image} />
			<ProjectDetailsWrap>
				<ProjectHeaderWrap className="section-ptb-xl">
					<div className="row">
						<div className="col-1 offset-1">
							<div className="rn-project-meta-inner">
								{projectData.title && <Heading {...title}>{projectData.title}</Heading>}
								<ProjectHeaderMeta>
									{projectData.location && (
										<ProjectType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
											<Heading {...metaHeading}>{titles.location}</Heading>
											<Text {...metaText}>{projectData.location}</Text>
										</ProjectType>
									)}
									{projectData.subtitle && (
										<ProjectType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
											<Heading {...metaHeading}>{titles.sector}</Heading>
											<Text {...metaText}>{projectData.subtitle}</Text>
										</ProjectType>
									)}
									{projectData.year && (
										<ProjectType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
											<Heading {...metaHeading}>{titles.year}</Heading>
											<Text {...metaText}>{projectData.year}</Text>
										</ProjectType>
									)}
								</ProjectHeaderMeta>
							</div>
						</div>
						<div className="col-2 offset-1">
							<div className="rn-project-content" dangerouslySetInnerHTML={{__html: bodyHtml}}>
							</div>
						</div>
					</div>
				</ProjectHeaderWrap>
				{cover_image && (
					<FullwidthBox>
						<Controller>
							<Scene classToggle="animated" triggerElement="#project-image-1" triggerHook="onCenter">
								<div className="rn_surface" id="project-image-1">
									<Image src={cover_image} alt="single" />
								</div>
							</Scene>
						</Controller>
					</FullwidthBox>
				)}
			</ProjectDetailsWrap>
			<NextProjectWrap>
				<div className="project-container">
					<div className="col-1 offset-1">
						<SectionTitle
							title={next ? titles.nextProject : titles.prevProject}
							subtitle={titles.prevNextSubTitle}
						/>
					</div>

					<div className="project-wrapper">
						{next && (
							<Project
								{...nextProjectStyle}
								isEven={true}
								id={next.jsonId}
								image={next.featured_image}
								title={next.title}
								location={next.location}
								subtitle={next.subtitle}
								titles={titles}
								lang={lang}
							/>
						)}
						{!next && previous && (
							<Project
								{...nextProjectStyle}
								isEven={true}
								id={previous.jsonId}
								image={previous.featured_image}
								title={previous.title}
								location={previous.location}
								subtitle={previous.subtitle}
								titles={titles}
								lang={lang}
							/>
						)}
					</div>
				</div>
			</NextProjectWrap>
		</Layout>
	)
}

export const query = graphql`
    query($id: String!){
        projectsJson(jsonId: {eq: $id}){
            jsonId
			i18n {
				ar {
					title
					location
					subtitle
					bodyHtml
					body
				}
				en {
					title
					location
					subtitle
					bodyHtml
					body
				}
			}
            year
			cover_image {
				childImageSharp {
					gatsbyImageData(width: 1760, height: 990, layout: CONSTRAINED, quality: 100, placeholder: TRACED_SVG)
				}
			}
			page_cover_image {
				childImageSharp {
					gatsbyImageData(width: 1920, height: 750, layout: CONSTRAINED, quality: 100, placeholder: TRACED_SVG)
				}
			}
        }
		translationJson {
			projects {
				projectName { ar en }
				sector { ar en }
				location { ar en }
				year { ar en }
				prevNextSubTitle { ar en }
				nextProject { ar en }
				prevProject { ar en }
			}
		}
    }
`;

ProjectTemplate.propTypes = {
	title: PropTypes.object,
	metaHeading: PropTypes.object,
	metaText: PropTypes.object
}

ProjectTemplate.defaultProps = {
	title: {
		color: 'primary',
		fontSize: '40px',
		lineHeight: '55px',
		responsive: {
			medium: {
				fontSize: '24px',
				lineHeight: 'initial'
			}
		}
	},
	metaHeading: {
		as: 'h6',
		color: 'primary',
		fontSize: '14px',
		fontweight: 700,
		letterspacing: '0px',
		mb: '12px',
	},
	metaText: {
		m: 0,
		fontSize: '15px',
		color: '#000000',
		letterspacing: '0px',
	},
	nextProjectStyle: {
		mt: '100px',
		responsive: {
			medium: {
				mt: '60px'
			}
		}
	}
}

export default ProjectTemplate;
